<template>
  <nav v-if="debugMode == 'TRUE'">
    <router-link to="/">TOP</router-link> |
    <router-link to="/user/京都店">利用者</router-link> |
    <router-link to="/operator/綱澤">オペレーター</router-link> |
    <router-link to="/admin/宮村">管理者</router-link>
  </nav>
  <router-view/>
</template>

<script>
  export default {
    data(){
      return{
        debugMode:process.env.VUE_APP_DEBUG_FLUG,
      }
    }
  }
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
