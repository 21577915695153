import { createStore } from 'vuex';

// state の初期化関数
function sekiInitializer() {
  let data = [];
  let count = 10;
  for (let i = 0; i < count; i++) {
    data.push({
      data: i + 1,
      flag: 0,
      user: ''
    });
  }
  return data;
}

// Vuex ストアのモジュール定義
const myModule = {
  state: {
    // 初期化関数を定義し、その戻り値を state の初期値とする
    seki: sekiInitializer(),
    formData:{
      Title :'',
      Data  :'',
      User  :''
    }
  },
  mutations: {
    // ミューテーションを定義する場合はここに記述
    seki(state, payload){
      state.seki = payload
    },
    formData(state, payload){
      state.formData = payload
    },
  },
  actions: {
    // アクションを定義する場合はここに記述
  },
  getters: {
    // ゲッターを定義する場合はここに記述
  }
};

// Vuex ストアを作成
const store = createStore({
  modules: {
    myModule
  }
});

export default store;