<template>
  <div class="home">
    <h2>ようこそ</h2>
<!--    <img alt="Vue logo" src="../assets/logo.png">-->
<!--    <div>-->
<!--      <input type="text" v-model="userData">-->
<!--      <button @click="addUser">登録</button>-->
<!--    </div>-->
    <p>展開されているURLを参照してください</p>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
export default {
  name: 'TopView',
  components: {
    // HelloWorld

  },
  data: function() {
    return {
      userData:''
    }
  },
  created() {
    // if(this.getStrage('user')){
    //   let data = this.getStrage('user')
    //   console.log(data)
    //   this.userData = data
    // }
  },
  methods:{
    addUser(){
      this.setStrage('user',this.userData)
    },
    checkStrage(target){
      return JSON.parse(localStorage.getItem(target));
    },
    getStrage(target){
      return localStorage.getItem(target);
    },
    setStrage(target,value){
      localStorage.setItem(target,value);
    },
    removeStrage(target){
      localStorage.removeItem(target);
    },
  }
}
</script>
