async function sendDataToKintone(formData) {
    const { Title, Data ,User} = formData;
    const dataToSend = {
        Title : {value:Title},
        Data  : {value:Data},
        User  : {value:User},
    };

    const appId = '372';
    const apiToken = 'tDwij0CCU6RizFu0Z5d3180x4vznj4q5bJ7nHDK1';
    const endpointUrl = `https://zion-corp.cybozu.com/k/v1/record.json`;

    try {
        const response = await fetch(endpointUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-Cybozu-API-Token': apiToken
            },
            body: JSON.stringify({
                app: appId,
                record: dataToSend
            })
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const responseData = await response.json();
        return responseData; // レスポンスデータを返す
    } catch (error) {
        console.error('Error sending data:', error);
        throw error; // エラーを再度投げる
    }
}

export { sendDataToKintone };