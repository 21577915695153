import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import moment from 'moment'; // Moment.jsをインポート
import { sendDataToKintone } from './assets/javascript/api';

import Heart from 'vue-material-design-icons/Heart.vue';
import Airplane from 'vue-material-design-icons/Airplane.vue';
import AccountCircle from 'vue-material-design-icons/AccountCircle.vue';
import ChatProcessingOutline from 'vue-material-design-icons/ChatProcessingOutline.vue';
import ChatProcessing from 'vue-material-design-icons/ChatProcessing.vue';
import 'vue-material-design-icons/styles.css';

// Vueアプリケーションの作成
const app = createApp(App);

// アイコンコンポーネントをグローバルに登録
app.component('IconHeart', Heart);
app.component('IconAirplane', Airplane);
app.component('IconAccountCircle', AccountCircle);

//チャットアイコン
app.component('IconChat', ChatProcessing);
app.component('IconChatOut', ChatProcessingOutline);

// グローバルな関数をVueアプリケーションのプロパティとして登録
app.config.globalProperties.$sendDataToKintone = sendDataToKintone;

app.use(store);
app.use(router);

// Moment.jsをVueアプリケーションのインスタンスにグローバルで使用可能にする
app.config.globalProperties.$moment = moment;

// Vueアプリケーションをマウント
app.mount('#app');